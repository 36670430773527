import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  if (!orderId) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Box sx={{ mb: 3 }}>
              <BsCheckCircleFill size={64} color="#2e7d32" />
            </Box>

            <Alert severity="success" sx={{ mb: 3 }}>
              Ödeme başarıyla tamamlandı!
            </Alert>

            <Typography variant="h6" sx={{ mb: 2 }}>
              Sipariş No: {orderId}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/")}
            >
              Panele Dön
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentSuccess;
