import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { BiSolidChart } from "react-icons/bi";
import { BsCreditCard2FrontFill, BsGlobe2 } from "react-icons/bs";
import {
  FaHeadset,
  FaMoneyCheckAlt,
  FaPiggyBank,
  FaRegClock,
  FaShippingFast,
  FaShoppingCart,
} from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";
import { MdExpandMore } from "react-icons/md";
import { RiExchangeDollarFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

// Global Styles (from App.css)
const globalStyles = {
  "*": {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  body: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  },
  ".logo": {
    height: "40px",
    margin: "10px",
  },
  ".MuiCard-root": {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  "@media (max-width: 600px)": {
    ".MuiTypography-h2": {
      fontSize: "2rem !important",
    },
    ".MuiTypography-h3": {
      fontSize: "1.75rem !important",
    },
    ".MuiTypography-h5": {
      fontSize: "1.25rem !important",
    },
  },
  "::-webkit-scrollbar": {
    width: "8px",
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#004A8D",
    borderRadius: "4px",
    "&:hover": {
      background: "#003366",
    },
  },
};

// Theme Constants
const PRIMARY_COLOR = "#004481";
const SECONDARY_COLOR = "#02a5a5";

// Theme Configuration
const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: "3.5rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.4,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: globalStyles,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
      },
    },
  },
});

// Styled Components
const HeroSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${PRIMARY_COLOR} 0%, ${SECONDARY_COLOR} 100%)`,
  minHeight: "85vh",
  position: "relative",
  display: "flex",
  alignItems: "center",
  color: "white",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "url('/grid-pattern.svg')",
    opacity: 0.1,
  },
}));

const InfoCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4.5),
  height: "100%",
  borderRadius: "24px",
  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2.5),
  border: `1px solid ${PRIMARY_COLOR}05`,
  background: "linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${SECONDARY_COLOR} 30%, ${SECONDARY_COLOR}CC 90%)`,
  color: "white",
  padding: "12px 28px",
  borderRadius: "4px",
  fontWeight: 500,
  textTransform: "none",
  fontSize: "1rem",
  boxShadow: `0 2px 8px ${SECONDARY_COLOR}40`,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    background: `linear-gradient(45deg, ${SECONDARY_COLOR}CC 30%, ${SECONDARY_COLOR} 90%)`,
    transform: "translateY(-1px)",
    boxShadow: `0 4px 12px ${SECONDARY_COLOR}60`,
  },
}));

const OutlineButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderColor: "rgba(255,255,255,0.5)",
  "&:hover": {
    borderColor: "white",
    backgroundColor: "rgba(255,255,255,0.1)",
  },
}));

const Logo = styled("img")({
  height: "40px",
  margin: "0 10px",
  filter: "brightness(0) invert(1)",
});

const LogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
});

// Mock Logo Data
const logos = {
  garantiLogo: "/logos/garantibbva.png",
  garanti2Logo: "/logos/garanti-2.png",
  mastercardLogo: "/logos/mastercard.png",
  ticimaxLogo: "/logos/ticimax.png",
  ticimaxDarkLogo: "/logos/ticimax-dark.png",
  logoIsbasi: "/logos/logo-isbasi.png",
  ptsLogo: "/logos/pts.png",
  qpienLogo: "/logos/qpien.png",
  postubyLogo: "/logos/postuby.png",
  proparsLogo: "/logos/propars.png",
  megaWhiteLogo: "/logos/mega-logo-white.png",
  megaPaymentLogo: "/logos/mega-logo-payment.png",
};

// General Features Section array içeriği
const generalFeatures = [
  {
    title: "Profesyonel Danışmanlık ve Destek",
    content:
      "İşletmeler, süreç boyunca profesyonel danışmanlık hizmeti alarak MegaMerchant'ın yönlendirmeleri ve paket kapsamındaki iş ortaklarının sunduğu hizmetler sayesinde e-ihracata hızla başlayabilir ve doğru stratejilerle ilerler.",
  },
  {
    title: "Yurt Dışına Açılma Fırsatı",
    content:
      "İşletmeler, global pazar yerlerine erişim sağlayarak ürünlerini dünyanın dört bir yanına ulaştırabilirler. Bu, markaların uluslararası düzeyde tanınmasını sağlar.",
  },
  {
    title: "Maliyet Avantajı",
    content:
      "Kampanya kapsamında iş ortaklarından özel indirimler ve destekler sayesinde lojistik, kargo ve e-ticaret altyapısı gibi hizmetlerde maliyet avantajı elde edilir. Ayrıca mikro ihracat kapsamında KDV iadesi gibi vergi avantajlarından faydalanılır.",
  },
  {
    title: "Teknolojik Destek ve Entegrasyonlar",
    content:
      "E-ihracat için gerekli olan teknik altyapı ve pazar yeri entegrasyonları hızlı bir şekilde tamamlanır. SEO optimizasyonu, çoklu para birimi yönetimi, sosyal medya entegrasyonları ve canlı destek özellikleri sunan araçlar sayesinde işletmelerin operasyonel verimliliği artırılır.",
  },
  {
    title: "Faturalama Kolaylığı",
    content:
      "Kampanya dahilinde sunulan faturalama hizmetleri, işletmelerin finansal süreçlerini kolaylaştırır. İşletmeler, e-fatura ve e-arşiv işlemlerini hızlı ve sorunsuz bir şekilde gerçekleştirebilir.",
  },
  {
    title: "Hızlı ve Güvenli Lojistik",
    content:
      "Kampanya kapsamında sunulan uluslararası lojistik çözümleri sayesinde ürünlerin güvenli bir şekilde yurt dışına teslim edilmesi sağlanır.",
  },
  {
    title: "Tam Entegre Lojistik ve Ödeme Sistemleri",
    content:
      "Yurt dışı pazar yerlerinde ödeme ve lojistik süreçlerini kolaylaştırır. Entegre sistemler sayesinde sipariş takibi ve müşteri iletişimi sorunsuz yürütülür.",
  },
];

// Service Cards array içeriği
const serviceCards = [
  {
    title: "Garanti BBVA Sanal POS",
    logo: logos.garanti2Logo,
    description:
      "E-ihracat paketini satın aldığınızda Garanti BBVA sanal POS'unu 1 yıl ücretsiz olarak kullanabilirsiniz.",
    features: [
      "1 Yıl Ücretsiz Kullanım",
      "Güvenli Ödeme Altyapısı",
      "7/24 Teknik Destek",
      "Çoklu Döviz Desteği",
      "Kolay Entegrasyon",
    ],
    bgColor: "linear-gradient(135deg, #004481 0%, #072146 100%)",
    textColor: "#fff",
  },
  {
    title: "MegaMerchant",
    logo: logos.megaPaymentLogo,
    description: "E-İhracat İş Ortağınız.",
    features: [
      "Elleçleme",
      "İhracat",
      "Gümrükleme",
      "Depolama",
      "Fullfillment",
    ],
  },
  {
    title: "Propars E-ihracat Entegrasyonu",
    logo: logos.proparsLogo,
    description: "Global pazaryeri entegrasyonları ve yönetimi.",
    features: [
      "Global Pazaryeri Entegrasyonu",
      "Çoklu Dil Desteği",
      "Ürün ve Katalog Yönetimi",
      "Lojistik Entegrasyonu",
    ],
  },

  {
    title: "Ticimax E-Ticaret Altyapısı",
    logo: logos.ticimaxDarkLogo,
    description:
      "Profesyonel e-ticaret altyapısı ile global pazarlarda satış yapın.",
    features: [
      "Ürün ve Sipariş Yönetimi",
      "Kur Modülü ve Farklı Fiyat Tanımlama",
      "E-Fatura ve Raporlama",
      "7/24 Destek ve Güvenlik",
      "Yabancı Dil Desteği",
      "SEO ve Analitik",
      "Yurt dışı Kargo Entegrasyonu",
      "2000 GB Trafik + 10 E-posta",
    ],
  },
  {
    title: "Logo İşbaşı",
    logo: logos.logoIsbasi,
    description: "Kapsamlı e-Dönüşüm ve ön muhasebe çözümleri.",
    features: [
      "e-Fatura/e-Arşiv Fatura",
      "e-İhracat Fatura Düzenleme",
      "Alış-Satış Fatura Takibi",
      "Stok ve Finans Takibi",
      "Ücretsiz Entegrasyonlar",
      "1000 e-Fatura Kontör Hediye",
    ],
  },
  {
    title: "PTS Worldwide Express",
    logo: logos.ptsLogo,
    description: "Global lojistik çözümleri ve gümrük işlemleri desteği.",
    features: [
      "Uluslararası Ekspres Taşımacılık",
      "İndirimli Gönderim Fırsatları",
      "Gümrük İşlemleri Desteği",
      "Teslimat Takibi",
      "7/24 Müşteri Hizmetleri",
      "Kapıdan Kapıya Teslimat",
    ],
  },
  {
    title: "Qpien İletişim Çözümleri",
    logo: logos.qpienLogo,
    description: "Müşterilerinizle etkili iletişim için gelişmiş araçlar.",
    features: [
      "2.500 Tekil Kişi ile Mesajlaşma",
      "Canlı Destek",
      "Özel Etiket ve Hazır Cevaplar",
      "Sosyal Medya Entegrasyonları",
      "Teknik Destek",
    ],
  },
  {
    title: "Postuby İçerik Yönetimi",
    logo: logos.postubyLogo,
    description: "AI destekli içerik üretimi ve sosyal medya yönetimi.",
    features: [
      "İçerik Şablonları",
      "AI ile Video Oluşturma",
      "Sosyal Medya İçerik Yönetimi",
      "AI İçerik Paylaşım Takvimi",
    ],
  },

];

// E-ihracat Faydaları array içeriği
const exportBenefits = [
  {
    icon: (
      <BsGlobe2 style={{ fontSize: 32, color: theme.palette.primary.main }} />
    ),
    title: "Global Erişim",
    content:
      "E-ihracat, işletmelere ürünlerini dünya genelindeki geniş bir müşteri kitlesine sunma imkanı sağlar. Bu durum, markaların bilinirliğini ve satış potansiyelini artırır.",
  },
  {
    icon: (
      <FaRegClock style={{ fontSize: 32, color: theme.palette.primary.main }} />
    ),
    title: "7/24 Satış İmkanı",
    content:
      "Dijital platformlar üzerinden kesintisiz satış yapabilme imkanı. Farklı saat dilimlerindeki müşterilere her an ulaşabilme fırsatı.",
  },
  {
    icon: (
      <RiExchangeDollarFill
        style={{ fontSize: 32, color: theme.palette.primary.main }}
      />
    ),
    title: "Döviz Geliri",
    content:
      "Yurt dışı satışlardan elde edilen döviz gelirleri ile kur dalgalanmalarına karşı doğal koruma ve yüksek kar marjı.",
  },
  {
    icon: (
      <FaPiggyBank
        style={{ fontSize: 32, color: theme.palette.primary.main }}
      />
    ),
    title: "Düşük Maliyet",
    content:
      "Geleneksel ihracata göre daha düşük operasyonel maliyetler. Depo, showroom gibi fiziksel yatırım gerektirmez.",
  },
  {
    icon: (
      <FaMoneyCheckAlt
        style={{ fontSize: 32, color: theme.palette.primary.main }}
      />
    ),
    title: "KDV İadesi",
    content:
      "E-ihracat kapsamında yapılan satışlarda KDV iadesi alabilme imkanı. Vergisel avantajlardan yararlanma fırsatı.",
  },
  {
    icon: (
      <FaShippingFast
        style={{ fontSize: 32, color: theme.palette.primary.main }}
      />
    ),
    title: "Kolay Lojistik",
    content:
      "Mikro ihracat kapsamında basitleştirilmiş gümrük süreçleri. Hızlı ve kolay kargo gönderimi.",
  },
];

// Gereksinimler array içeriği
const requirements = [
  {
    title: "E-Ticaret Platformu veya Pazar Yeri Hesabı",
    content:
      "E-ihracata başlamak için Amazon, Etsy, eBay gibi uluslararası pazar yerlerinde mağaza açmak ya da Shopify gibi platformlar üzerinden kendi e-ticaret sitenizin kurulması gerekmektedir.",
  },
  {
    title: "Lojistik ve Kargo Hizmetleri",
    content:
      "Ürünlerin yurtdışına güvenli ve hızlı bir şekilde teslim edilmesi için kargo firmalarıyla anlaşma yapılmalıdır. Gümrük süreçlerinin yönetimi için gerekli hazırlıklar tamamlanmalıdır.",
  },
  {
    title: "Uluslararası Ödeme Sistemleri",
    content:
      "Yurtdışındaki müşterilerin ödemelerini alabilmek için PayPal, Stripe gibi ödeme sistemleri kullanılmalıdır. Bu sistemler, farklı para birimlerinde ödeme kabul edilmesini kolaylaştırır.",
  },
  {
    title: "Yerelleştirilmiş İçerik ve Müşteri Desteği",
    content:
      "Ürün açıklamaları ve müşteri hizmetleri, satış yapılacak ülkenin diline ve kültürüne uygun hale getirilmelidir. Bu, müşteri memnuniyetini artırır ve satışları olumlu etkiler.",
  },
];

// Main Component
function GarantiModalContent() {
  const scrollToFeatures = () => {
    const featuresSection = document.getElementById("features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const iconStyles = {
    primary: {
      fontSize: 48,
      color: PRIMARY_COLOR,
      filter: "drop-shadow(0 2px 4px rgba(0,68,129,0.3))",
    },
    secondary: {
      fontSize: 48,
      color: SECONDARY_COLOR,
      filter: "drop-shadow(0 2px 4px rgba(2,165,165,0.3))",
    },
    small: {
      fontSize: 32,
      color: PRIMARY_COLOR,
      filter: "drop-shadow(0 2px 4px rgba(0,68,129,0.3))",
    },
    check: {
      fontSize: 24,
      color: PRIMARY_COLOR,
      filter: "drop-shadow(0 2px 4px rgba(0,68,129,0.2))",
    },
  };

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        {/* Hero Section */}
        <HeroSection>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(135deg, #004481 0%, #072146 100%)`,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20h40v1H0z' fill='%23ffffff' fill-opacity='0.03'/%3E%3Cpath d='M20 0v40h1V0z' fill='%23ffffff' fill-opacity='0.03'/%3E%3C/svg%3E")`,
              backgroundSize: "40px 40px",
              opacity: 0.6,
            }}
          />
          <Container
            maxWidth="lg"
            sx={{ py: { xs: 10, md: 14 }, position: "relative" }}
          >
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    color: "#fff",
                  }}
                >
                  Garanti BBVA ve MegaMerchant ile Global E-Ticaret Fırsatı
                </Typography>

                <Typography
                  variant="h5"
                  sx={{ mb: 6, opacity: 0.9, lineHeight: 1.6, color: "#fff" }}
                >
                  Garanti BBVA ve MegaMerchant ile e-ihracatta sınırları aşın!
                </Typography>

                <Box
                  sx={{
                    mb: 6,
                    p: 3,
                    background: "rgba(255,255,255,0.1)",
                    backdropFilter: "blur(10px)",
                    borderRadius: 2,
                    border: "1px solid rgba(255,255,255,0.2)",
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <BsCreditCard2FrontFill
                      style={{
                        fontSize: 48,
                        color: "#fff",
                        filter: "drop-shadow(0 2px 4px rgba(255,255,255,0.2))",
                      }}
                    />
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 500, lineHeight: 1.6, color: "#fff" }}
                    >
                      Özel İndirim! 99.999₺ Yerine Sadece 69.999₺ + Global Ödeme
                      Kolaylığı
                    </Typography>
                  </Stack>
                </Box>

                <LogoContainer sx={{ mb: 8 }}>
                  <Logo src={logos.garantiLogo} alt="Garanti BBVA" />
                  <Logo src={logos.mastercardLogo} alt="Mastercard" />
                  <Logo src={logos.megaWhiteLogo} alt="Mega" />
                </LogoContainer>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                  <PrimaryButton
                    size="large"
                    onClick={() => navigate("/garanti-payment")}
                  >
                    Satın Al →
                  </PrimaryButton>
                  <OutlineButton
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      window.open(
                        "https://www.garantibbva.com.tr/isim-icin/kartlar-pos/ticari-kredi-kartlari",
                        "_blank"
                      );
                    }}
                    sx={{
                      color: "white",
                      borderColor: "rgba(255,255,255,0.5)",
                      "&:hover": {
                        borderColor: "white",
                        backgroundColor: "rgba(255,255,255,0.1)",
                      },
                    }}
                  >
                    Detaylı Bilgi
                  </OutlineButton>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  component="img"
                  src="/logos/hero-illustration.svg"
                  alt="E-ihracat"
                  sx={{
                    width: "120%",
                    height: "auto",
                    filter: "drop-shadow(0 20px 40px rgba(0,0,0,0.25))",
                    transform: "scale(1.1) translateX(-5%)",
                    animation: "float 6s ease-in-out infinite",
                    "@keyframes float": {
                      "0%, 100%": {
                        transform: "scale(1.1) translateX(-5%) translateY(0px)",
                      },
                      "50%": {
                        transform:
                          "scale(1.1) translateX(-5%) translateY(-20px)",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </HeroSection>

        {/* Features Section */}
        <Box sx={{ py: { xs: 10, md: 14 }, background: "#fff" }}>
          <Container maxWidth="lg" id="features">
            <Grid container spacing={3} justifyContent="center" sx={{ mb: 10 }}>
              <Grid item xs={12} md={8} textAlign="center">
                <Typography variant="h2" gutterBottom>
                  Neden Garanti BBVA E-İhracat?
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  Global ticarette rekabet avantajı sağlayan çözümler
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              {[
                {
                  icon: <BsCreditCard2FrontFill style={iconStyles.primary} />,
                  title: "Ücretsiz Sanal POS",
                  description:
                    "1 yıl boyunca komisyon avantajlı sanal POS kullanımı",
                },
                {
                  icon: <BiSolidChart style={iconStyles.primary} />,
                  title: "Global Pazarlama",
                  description:
                    "Yurtdışı pazarlarda büyümeniz için özel destekler",
                },
                {
                  icon: <FaShippingFast style={iconStyles.primary} />,
                  title: "Lojistik Çözümler",
                  description:
                    "Anlaşmalı kargo firmaları ile indirimli gönderim",
                },
                {
                  icon: <FaMoneyCheckAlt style={iconStyles.primary} />,
                  title: "Finansal Destek",
                  description:
                    "E-ihracat işlemleriniz için özel kredi imkanları",
                },
              ].map((feature, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <InfoCard>
                    {feature.icon}
                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                      {feature.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {feature.description}
                    </Typography>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Benefits Section */}
        <Box sx={{ py: { xs: 10, md: 14 }, background: "rgba(0,68,129,0.02)" }}>
          <Container maxWidth="lg">
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h3" gutterBottom>
                  E-İhracatta Kazançlı Başlangıç
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                  Garanti BBVA e-ihracat paketi ile global ticarete güvenle
                  başlayın. Avantajlı komisyon oranları ve özel desteklerle
                  işinizi büyütün.
                </Typography>
                <Stack spacing={3}>
                  {[
                    "Düşük komisyonlu ödeme çözümleri",
                    "KDV iade süreçlerinde kolaylık",
                    "Döviz işlemlerinde rekabetçi kurlar",
                    "7/24 teknik destek",
                  ].map((benefit, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    >
                      <HiCheckCircle style={iconStyles.check} />
                      <Typography>{benefit}</Typography>
                    </Box>
                  ))}
                </Stack>
                <Box sx={{ mt: 4 }}>
                  <PrimaryButton
                    onClick={() => navigate("/garanti-payment")}
                  >
                    Satın Al →
                  </PrimaryButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  {[
                    {
                      icon: <RiExchangeDollarFill style={iconStyles.small} />,
                      title: "Döviz İşlemleri",
                      description:
                        "Rekabetçi kurlarla döviz alım-satım işlemleri",
                    },
                    {
                      icon: <BsGlobe2 style={iconStyles.small} />,
                      title: "Global Erişim",
                      description: "190+ ülkede ödeme kabul etme imkanı",
                    },
                    {
                      icon: <FaRegClock style={iconStyles.small} />,
                      title: "Hızlı Kurulum",
                      description: "24 saat içinde sisteminizi aktif edin",
                    },
                    {
                      icon: <FaPiggyBank style={iconStyles.small} />,
                      title: "Maliyet Avantajı",
                      description: "İlk yıl ücretsiz POS kullanımı",
                    },
                  ].map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <InfoCard>
                        <Box sx={{ mb: 2 }}>{item.icon}</Box>
                        <Typography variant="h6" gutterBottom>
                          {item.title}
                        </Typography>
                        <Typography color="text.secondary">
                          {item.description}
                        </Typography>
                      </InfoCard>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Package Content Section */}
        <Box sx={{ py: { xs: 10, md: 14 }, background: "#fff" }}>
          <Container maxWidth="lg">
            <Grid container spacing={3} justifyContent="center" sx={{ mb: 10 }}>
              <Grid item xs={12} md={8} textAlign="center">
                <Typography variant="h2" gutterBottom>
                  Pakette Neler Var?
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  E-ihracat paketimiz ile global ticarette ihtiyacınız olan her
                  şey tek pakette
                </Typography>
              </Grid>
            </Grid>

            {/* Service Cards */}
            <Grid container spacing={4}>
              {serviceCards.map((service, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <InfoCard
                    sx={{
                      background:
                        service.bgColor ||
                        "linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)",
                    }}
                  >
                    <Box sx={{ mb: 3, height: "100px" }}>
                      <img
                        src={service.logo}
                        alt={service.title}
                        style={{
                          height: "100%",
                          filter:
                            service.textColor === "#fff"
                              ? "brightness(0) invert(1)"
                              : "none",
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        color: service.textColor || "text.primary",
                      }}
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      sx={{
                        mb: 3,
                        opacity: 0.9,
                        color: service.textColor || "text.secondary",
                      }}
                    >
                      {service.description}
                    </Typography>
                    <Box component="ul" sx={{ pl: 2, m: 0 }}>
                      {service.features.map((feature, idx) => (
                        <Box
                          component="li"
                          key={idx}
                          sx={{
                            mb: 1,
                            color: service.textColor || "text.secondary",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 6,
                              height: 6,
                              borderRadius: "50%",
                              bgcolor:
                                service.textColor || theme.palette.primary.main,
                              flexShrink: 0,
                            }}
                          />
                          {feature}
                        </Box>
                      ))}
                    </Box>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* General Features Section */}
        <Box
          sx={{
            py: { xs: 10, md: 14 },
            background: `linear-gradient(135deg, #004481 0%, #072146 100%)`,
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Crect width='1' height='1' x='4' y='4' /%3E%3C/g%3E%3C/svg%3E")`,
              backgroundSize: "20px 20px",
              opacity: 1,
            },
          }}
        >
          <Container maxWidth="lg" sx={{ position: "relative" }}>
            <Grid container spacing={3} justifyContent="center" sx={{ mb: 10 }}>
              <Grid item xs={12} md={8} textAlign="center">
                <Typography variant="h2" gutterBottom sx={{ color: "#fff" }}>
                  Genel Özellikler
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 4, color: "rgba(255,255,255,0.8)" }}
                >
                  E-ihracat paketi, Türkiye'deki OBİ ve KOBİ'lerin uluslararası
                  pazarlara hızlı ve etkili bir şekilde açılmalarını sağlamak
                  amacıyla tasarlanmıştır.
                </Typography>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    window.open(
                      "https://www.garantibbva.com.tr/isim-icin/kartlar-pos/ticari-kredi-kartlari",
                      "_blank"
                    );
                  }}
                  sx={{
                    color: "#fff",
                    borderColor: "rgba(255,255,255,0.3)",
                    "&:hover": {
                      borderColor: "#fff",
                      backgroundColor: "rgba(255,255,255,0.1)",
                    },
                  }}
                >
                  Detaylı Bilgi Al →
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12}>
                {generalFeatures.map((item, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      mb: 2,
                      background: "rgba(255,255,255,0.03)",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                      "&:before": { display: "none" },
                      borderRadius: "4px !important",
                      overflow: "hidden",
                      border: "1px solid rgba(255,255,255,0.1)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<MdExpandMore size={24} color="#fff" />}
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          my: 2,
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          color: "#fff",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ pb: 3, background: "rgba(255,255,255,0.02)" }}
                    >
                      <Typography
                        sx={{ lineHeight: 1.7, color: "rgba(255,255,255,0.8)" }}
                      >
                        {item.content}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* E-Export Info Section */}
        <Box
          sx={{ py: { xs: 10, md: 14 }, background: "rgba(2,165,165,0.02)" }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={3} justifyContent="center" sx={{ mb: 10 }}>
              <Grid item xs={12} md={8} textAlign="center">
                <Typography variant="h2" gutterBottom>
                  E-ihracat Nedir?
                </Typography>
                <Typography variant="h5" color="text.secondary" sx={{ mb: 6 }}>
                  Global ticarette dijital dönüşümün anahtarı
                </Typography>
              </Grid>
            </Grid>

            <InfoCard
              sx={{
                p: 6,
                mb: 8,
                position: "relative",
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23004481' fill-opacity='0.08'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                  backgroundSize: "30px 30px",
                  opacity: 0.8,
                  zIndex: 0,
                },
              }}
            >
              <Grid
                container
                spacing={6}
                alignItems="center"
                sx={{ position: "relative", zIndex: 1 }}
              >
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ lineHeight: 1.8, mb: 4 }}>
                    Ürün veya hizmetlerin online pazaryerleri, e-ticaret
                    siteleri, sosyal medya sayfaları ve benzeri dijital kanallar
                    üzerinden yurt dışındaki müşterilere yapılan satış
                    işlemlerine e-ihracat adı verilir.
                  </Typography>
                  <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
                    Geleneksel ihracattan farklı olarak e-ihracat; lojistik,
                    gümrük ve pazarlama süreçlerini dijitalleştirerek daha kolay
                    ve hızlı bir ihracat modeli sunar. Özellikle KOBİ'ler ve
                    bireysel girişimciler için düşük maliyetle global müşteri
                    kitlesine ulaşma imkanı sunar.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 4,
                      background: "rgba(0,68,129,0.04)",
                      borderRadius: 2,
                      position: "relative",
                      zIndex: 2,
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 20px rgba(0,68,129,0.06)",
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      Kimler e-ihracat yapabilir?
                    </Typography>
                    <Stack spacing={2}>
                      {[
                        {
                          title: "Küçük ve Orta Ölçekli İşletmeler (KOBİ'ler)",
                          desc: "Ürünlerini yurtdışına satmak ve yeni pazarlara açılmak isteyen işletmeler",
                        },
                        {
                          title: "Bireysel Girişimciler",
                          desc: "El yapımı ürünler, sanat eserleri veya özel tasarımlar gibi niş ürünler sunan bireyler",
                        },
                        {
                          title: "Üretici Firmalar",
                          desc: "Ürünlerini doğrudan yurtdışına satmak isteyen ve aracıları ortadan kaldırmayı hedefleyen firmalar",
                        },
                        {
                          title: "Mevcut E-Ticaret Satıcıları",
                          desc: "Hali hazırda yurtiçinde e-ticaret yapan ve operasyonlarını global pazarlara genişletmek isteyen satıcılar",
                        },
                      ].map((item, index) => (
                        <Box key={index} sx={{ display: "flex", gap: 2 }}>
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: "50%",
                              bgcolor: "primary.main",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexShrink: 0,
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {index + 1}
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, color: "primary.main" }}
                            >
                              {item.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.desc}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </InfoCard>

            {/* E-ihracat Faydaları */}
            <Box sx={{ mt: 24, mb: 8 }}>
              <Typography variant="h2" gutterBottom align="center">
                E-ihracat Yapmanın Faydaları
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                align="center"
                sx={{ mb: 8 }}
              >
                E-ihracat ile işinizi global pazarlara taşıyın
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {exportBenefits.map((benefit, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <InfoCard>
                    <Box
                      sx={{
                        mb: 3,
                        width: 64,
                        height: 64,
                        borderRadius: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgba(0,68,129,0.05)",
                      }}
                    >
                      {benefit.icon}
                    </Box>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: 600, color: "primary.main" }}
                    >
                      {benefit.title}
                    </Typography>
                    <Typography color="text.secondary" sx={{ lineHeight: 1.7 }}>
                      {benefit.content}
                    </Typography>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Requirements Section */}
        <Box
          sx={{ py: { xs: 10, md: 14 }, background: "rgba(2,165,165,0.03)" }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              gutterBottom
              color="primary"
              align="center"
              sx={{ mb: 8 }}
            >
              E-ihracat Yapmak için Gerekenler
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {requirements.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <InfoCard>
                    <Typography variant="h5" gutterBottom color="primary">
                      {item.title}
                    </Typography>
                    <Typography sx={{ lineHeight: 1.7 }}>
                      {item.content}
                    </Typography>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* How to Get Section */}
        <Box
          sx={{ py: { xs: 10, md: 14 }, background: "rgba(2,165,165,0.02)" }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              gutterBottom
              color="primary"
              align="center"
              sx={{ mb: 8 }}
            >
              E-ihracat Paketine Nasıl Sahip Olabilirim?
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {[
                {
                  icon: (
                    <BsCreditCard2FrontFill
                      style={{
                        fontSize: 40,
                        color: theme.palette.primary.main,
                      }}
                    />
                  ),
                  title: "Garanti BBVA Ticari Kart",
                  description:
                    "GarantiBBVA Mastercard logolu ticari karta sahip olmak gerekir.",
                },
                {
                  icon: (
                    <FaShoppingCart
                      style={{
                        fontSize: 40,
                        color: theme.palette.primary.main,
                      }}
                    />
                  ),
                  title: "Paketi Satın Alın",
                  description:
                    "E-ihracat paketini satın aldıktan sonra size özel başlangıç pakedine sahip olabilirsiniz.",
                },
                {
                  icon: (
                    <FaHeadset
                      style={{
                        fontSize: 40,
                        color: theme.palette.primary.main,
                      }}
                    />
                  ),
                  title: "Uzman Desteği",
                  description:
                    "Başlangıç için uzmanlar tarafından aranıp, başlangıç hakkında detaylı bilgi alabilirsiniz.",
                },
              ].map((step, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <InfoCard
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        mb: 2,
                        width: 80,
                        height: 80,
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgba(0,68,129,0.05)",
                      }}
                    >
                      {step.icon}
                    </Box>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                      }}
                    >
                      {index + 1}. {step.title}
                    </Typography>
                    <Typography color="text.secondary" sx={{ lineHeight: 1.7 }}>
                      {step.description}
                    </Typography>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* CTA Section */}
        <Box
          sx={{
            py: { xs: 10, md: 14 },
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
            color: "white",
            textAlign: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h3" gutterBottom>
              E-İhracata Hemen Başlayın
            </Typography>
            <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
              Garanti BBVA güvencesiyle global ticarete açılın
            </Typography>
  
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* go to payment button */}
              <PrimaryButton
                onClick={() => navigate("/garanti-payment")}
                sx={{
                  width: "280px",
                }}
              >
                Satın Al →
              </PrimaryButton>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default GarantiModalContent;
