import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const GarantiPackageCard = ({
  packageData,
  setIsGarantiModalOpen,
  setSelectedPackage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="outlined"
        sx={{ mb: 2, py: 2, px: 1, borderColor: "#ccc" }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{ color: "#E65A29", fontWeight: "bold", mb: 1 }}
          >
            {packageData.title}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box display={"flex"} justifyContent={"space-between"}>
            <List disablePadding>
              {packageData.advantages.map((advantage, index) => (
                <ListItem key={index} disableGutters sx={{ py: 0.1 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <CheckIcon style={{ color: "green" }} />
                  </ListItemIcon>
                  <ListItemText primary={advantage} />
                </ListItem>
              ))}
            </List>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="flex-start"
              alignItems="end"
            >
              <Typography variant="body2" color="textSecondary" mb={1}>
                {t("oneTimePayment")}
              </Typography>
              <Typography
                variant="h3"
                color="textSecondary"
                sx={{
                  textDecoration: "line-through",
                }}
                mb={1}
              >
                99.999 TL
              </Typography>
              <Typography variant="h1" color="textPrimary" mb={2}>
                {packageData.price.toLocaleString("tr-TR")}TL
              </Typography>
              <Button
                onClick={() => {
                  setSelectedPackage(packageData);
                  setIsGarantiModalOpen(true);
                }}
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                }}
              >
                {t("buy")}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default GarantiPackageCard;
