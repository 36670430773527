import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";

const PaymentError = () => {
  const [searchParams] = useSearchParams();

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Box sx={{ mb: 3 }}>
              <BiErrorCircle size={64} color="#d32f2f" />
            </Box>

            <Alert severity="error" sx={{ mb: 3 }}>
              Ödeme sırasında bir hata oluştu: {searchParams.get("message")}
            </Alert>

            <Typography variant="h6" sx={{ mb: 2 }}>
              Sipariş No: {searchParams.get("orderId")}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = "/payments")}
            >
              Tekrar Dene
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentError;
