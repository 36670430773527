import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useTheme,
} from "@mui/material";
import React from "react";
import GarantiModalContent from "./GarantiModalContent.js";

const GarantiModal = ({
  open,
  onClose,
  children,
  title = "",
  fullWidth = true,
}) => {
  const theme = useTheme();

  return (
    <Box className="emailConsent">
      <Dialog fullScreen fullWidth={fullWidth} open={open} onClose={onClose}>
        <DialogTitle
          sx={{ m: 0, p: 2, pl: 2, color: theme.palette.primary.main }}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        {/* A button go to checkout page */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent sx={{ p: 0 }}>
          <GarantiModalContent />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default GarantiModal;
