import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { UserSelectedPackageContext } from "../context/UserSelectedPackageContext";
import { useTheme } from "@emotion/react";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import Topbar from "./global/Topbar";

import CircularProgress from "@mui/material/CircularProgress";
import { getIyzicoPackages } from "../api";
import AdvertisingPackages from "../components/Payments/AdvertisingPackages";
import PackagesList from "../components/Payments/PackagesList";
import PaymentsTable from "../components/Payments/PaymentsTable";

const Payments = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  // const { userSelectedPackage } = useContext(UserSelectedPackageContext);
  const [data, setData] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  // get merchant Id
  const auth = useAuthUser();
  const merchantId = auth().merchantId;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/Merchant/GetMerchantPaymentsByMerchantId/${merchantId}`,
    headers: {
      Authorization: `Bearer ${Cookies.get("_auth")}`,
    },
    data: data,
  };

  const fetchData = async () => {
    try {
      const response = await axios.request(config);
      const responseDataItems = await response.data.items;
      const responseTotalItems = await response.data.total;
      setData(responseDataItems);
      setTotal(responseTotalItems);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  // useEffect for fetching data
  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchPackages = async () => {
      const packagesData = await getIyzicoPackages();
      console.log("packagesData", packagesData);
      if (packagesData.items) {
        const mappedPackages = packagesData.items.map((packagItem) => {
          return {
            ...packagItem,
            advantages: packagItem.advantages.split("\n"),
          };
        });
        setPackages(mappedPackages);
      }
    };
    fetchPackages();
  }, []);

  if (loading)
    return (
      <Box m={"20px"}>
        <Topbar title={t("myPayments")}></Topbar>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
          mt={2}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  if (error) return "Error!";

  return (
    <Box m={"20px"}>
      <Topbar title={t("paymentsAndPlan")}>
        {/* <Button
          startIcon={<PaymentIcons />}
          color="text"
          sx={{
            color: `${theme.palette.primary.fontColor}!important`,
            ml: 2,
            textTransform: "capitalize",
            border: "none",
            borderRadius: 0,
          }}
          // onClick={() => navigate("/payment")}
          onClick={() => alert(t("waitingForService"))}
        >
         {t("makePayment")}
        </Button> */}
      </Topbar>
      <Box mt={2} sx={{ height: "100%", backgroundColor: "#fff", p: 3 }}>
        {/* Grid  2 column*/}
        <Grid container spacing="20px">
          <Grid item xs={12} sm={6}>
            {/* <Packages userPackages={data} /> */}
            <PackagesList packages={packages} />
            <AdvertisingPackages />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PaymentsTable data={data} total={total} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Payments;
