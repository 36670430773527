import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import GarantiPaymentPage from "../components/Payments/GarantiPaymentPage";

const stripePromise = loadStripe(
  "pk_live_51P0QJA04TS9KAeqIjsXCGtuhNMMEoaP2gqG1FJMPW0g41wNAk795ugje78XnQz5RfsNebx7fKSDZOdOLPseToqRl00OW6FbjWx"
  //"pk_test_51PJuFoJF0DNaNSKfa9EwWRQCQ4w3TZNJUSLcnrUCyCZcTbB9xhHOROWRJ4mEpIhUI5f2QkhX9HfSv17vYwz9AJPl00ZYpjCawv"
);

const Payment = () => {
  const location = useLocation();
  const { packageData } = location.state || {};

  // packageData yoksa veya price 0 veya undefined ise ana sayfaya yönlendir
  if (!packageData || !packageData.price || packageData.price <= 0) {
    return <Navigate to="/" replace />;
  }

  const { price, name, priceId, mode } = packageData;

  // GarantiPaymentPage'e price ve diğer bilgileri prop olarak geçirelim
  return (
    <GarantiPaymentPage
      price={price}
      packageName={name}
      priceId={priceId}
      mode={mode}
    />
  );
};

export default Payment;
