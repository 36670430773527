import { Box, Button, Typography, useTheme } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { postIyzipay } from "../../api";
import { Modal } from "../../common";
import GarantiModal from "../../common/Modal/GarantiModal";
import LongDistanceSellingAgreement from "../Agreements/LongDistanceSellingAgreement";
import GarantiPackageCard from "./GarantiPackageCard";
import PackageCard from "./PackageCard";

const PackagesList = ({ packages }) => {
  const { t } = useTranslation();
  const auth = useAuthUser();
  const id = auth().merchantId;
  const navigate = useNavigate();
  const [postIyzipayData, setPostIyzipayData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGarantiModalOpen, setIsGarantiModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const theme = useTheme();

  const onAgreementAccepted = async () => {
    try {
      const PaymentDetailResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/Payment/GetPaymentDetailByMerchantId/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("_auth")}`,
          },
        }
      );

      if (PaymentDetailResponse.status === 200 && PaymentDetailResponse.data) {
        const { data } = PaymentDetailResponse;
        if (data.paymentDetailInfo) {
          const {
            buyerName,
            buyerSurname,
            email,
            tckn,
            address,
            city,
            country,
            contactFullName,
            id,
          } = data.paymentDetailInfo;
          const query = new URLSearchParams({
            Price: String(selectedPackage.price),
            ConversationId: String(selectedPackage.packageId),
            BasketId: String(Math.random() * 100).replace(".", "-"),
            BuyerId: id,
            BuyerName: buyerName,
            BuyerSurname: buyerSurname,
            Email: email,
            TCKN: tckn,
            Address: address,
            IpAddress: "127.0.0.1",
            City: city,
            Country: country,
            ContactFullName: contactFullName,
            ItemName: selectedPackage.title,
          });
          postIyzipay({
            query: `?${query}`,
          }).then((response) => {
            console.log({ response });
            if (response.formValue.status === "success") {
              setPostIyzipayData(response.formValue);
              Cookies.set(
                "iyzicoPayment",
                JSON.stringify({
                  amount: selectedPackage.price,
                  description: selectedPackage.title,
                  token: response.formValue.token,
                  conversationId: String(selectedPackage.packageId),
                })
              );

              // Parse the HTML content
              const parser = new DOMParser();
              const doc = parser.parseFromString(
                response.formValue.checkoutFormContent,
                "text/html"
              );

              // Extract script elements and append them to the DOM
              const scripts = doc.querySelectorAll("script");
              scripts.forEach((script) => {
                const newScript = document.createElement("script");
                newScript.type = "text/javascript";
                newScript.text = script.textContent;
                document.head.appendChild(newScript);
              });
            }
          });
        }
      }
    } catch {
      setIsWarningModalOpen(true);
    } finally {
      setIsModalOpen(false);
    }
  };
  const onWarningClicked = () => {
    navigate("/payment-information?comeFrom=packages-list");
  };
  return (
    <>
      <Box py={2}>
        {packages.map(
          // / if pkg.id === "a1baf4cc-b987-4045-b043-c1e99f35cdb3" then show the other package card
          (pkg) =>
            pkg.id !== "a1baf4cc-b987-4045-b043-c1e99f35cdb3" ? (
              <PackageCard
                setIsModalOpen={setIsModalOpen}
                setSelectedPackage={setSelectedPackage}
                key={pkg.id}
                packageData={pkg}
              />
            ) : (
              <GarantiPackageCard
                setIsGarantiModalOpen={setIsGarantiModalOpen}
                setSelectedPackage={setSelectedPackage}
                key={pkg.id}
                packageData={pkg}
              />
            )
        )}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="MESAFELİ HİZMET SATIŞI SÖZLEŞMESİ"
      >
        <LongDistanceSellingAgreement />
        <Box
          sx={{
            mt: 2,
            textAlign: "end",
            fontWeight: "700",
          }}
        >
          <Button
            style={{ border: `2px solid ${theme.palette.primary.main}` }}
            onClick={onAgreementAccepted}
          >
            Okudum Kabul Ediyorum
          </Button>
        </Box>
      </Modal>

      <GarantiModal
        open={isGarantiModalOpen}
        onClose={() => setIsGarantiModalOpen(false)}
        title="Garanti Ticari Kartlara Özel E İhracat Başlangıç Paketi"
      ></GarantiModal>

      <Modal
        open={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        title={t("paymentInformationNotFound")}
      >
        <Box
          sx={{
            mt: 0.5,
            fontWeight: "700",
          }}
        >
          <Typography variant="body1" gutterBottom>
            {t("weCouldNotFindYourPaymentInformation")}
            <br />
            {t(
              "pleaseSubmitYourPaymentInformationFirstAndThenBackHereAndBuyThePackage"
            )}
          </Typography>
          <Button
            style={{
              marginTop: "10px",
              border: `2px solid ${theme.palette.primary.main}`,
            }}
            onClick={onWarningClicked}
          >
            {t("ok")}
          </Button>
        </Box>
      </Modal>

      <div
        dangerouslySetInnerHTML={{
          __html: postIyzipayData.checkoutFormContent,
        }}
      ></div>
    </>
  );
};

export default PackagesList;
