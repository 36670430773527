import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import { useAuthUser } from "react-auth-kit";

// auth user bilgilerini al

// Tüm Garanti BIN'leri
const GARANTI_BINS = {
  1111111: { type: "PARACARD", org: "VISA", canInstallment: true },
  1554960: { type: "BONUS", org: "MASTER" },
  374421: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  374422: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  374423: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    isCommercial: true,
  },
  374424: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  374425: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    reward: "MR",
    isCommercial: true,
  },
  374426: { type: "AMEX", org: "AMEX", reward: "MR", isCommercial: true },
  374427: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Bonus" },
  374428: { type: "AMEX", org: "AMEX", canInstallment: true },
  374444: { type: "AMEX", org: "AMEX" },
  375622: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  37562248: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  375623: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  375624: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  375625: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    reward: "MR",
    isCommercial: true,
  },
  375626: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    reward: "MR",
    isCommercial: true,
  },
  375627: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  375628: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  375629: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  375631: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  377137: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    reward: "Miles",
    isCommercial: true,
  },
  377140: { type: "AMEX", org: "AMEX", canInstallment: false, reward: "Bonus" },
  377141: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  377142: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  377143: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  377596: {
    type: "AMEX",
    org: "AMEX",
    canInstallment: true,
    isCommercial: true,
  },
  377597: { type: "AMEX", org: "AMEX", canInstallment: true },
  377598: { type: "AMEX", org: "AMEX", canInstallment: true },
  377599: { type: "AMEX", org: "AMEX", canInstallment: true },
  378332: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Bonus" },
  379369: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Bonus" },
  379370: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "Miles" },
  379371: { type: "AMEX", org: "AMEX", canInstallment: true, reward: "MR" },
  401738: { type: "PARACARD", org: "VISA", reward: "Bonus" },
  403666: { type: "BONUS", org: "VISA", reward: "Bonus", isCommercial: true },
  404308: { type: "BONUS", org: "VISA", canInstallment: true, reward: "Bonus" },
  40430847: {
    type: "BONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  405051: {
    type: "PARACARD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  405090: {
    type: "PREPAID",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  406665: {
    type: "SFTK",
    org: "MASTER",
    canInstallment: true,
    isCommercial: true,
  },
  406666: {
    type: "SFTK",
    org: "MASTER",
    canInstallment: true,
    isCommercial: true,
  },
  409219: { type: "PREPAID", org: "VISA", canInstallment: false },
  410141: {
    type: "PARACARD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  413836: {
    type: "SM",
    org: "VISA",
    canInstallment: true,
    reward: "Miles",
    isCommercial: true,
  },
  420557: {
    type: "PREPAID",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  426886: { type: "BONUS", org: "VISA", canInstallment: true, reward: "Bonus" },
  426887: { type: "BONUS", org: "VISA", canInstallment: true, reward: "Bonus" },
  426888: { type: "BONUS", org: "VISA", canInstallment: true, reward: "Bonus" },
  426889: {
    type: "PARACARD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  427314: {
    type: "BNSTK",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  427315: {
    type: "BNSTK",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  428220: {
    type: "FLEXI",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  428221: {
    type: "FLEXI",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  432154: {
    type: "FLEXI",
    org: "VISA",
    canInstallment: true,
  },
  432951: {
    type: "SF",
    org: "VISA",
    canInstallment: true,
  },
  432952: {
    type: "SF",
    org: "VISA",
    canInstallment: true,
  },
  432954: {
    type: "SF",
    org: "MASTER",
    canInstallment: true,
  },
  448472: {
    type: "BNGCP",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  45683802: {
    type: "PARACARD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  461668: {
    type: "SM",
    org: "VISA",
    canInstallment: true,
    reward: "Miles",
  },
  462274: {
    type: "EKINBNS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  467293: {
    type: "SM",
    org: "VISA",
    canInstallment: true,
    reward: "Miles",
  },
  467294: {
    type: "SM",
    org: "VISA",
    canInstallment: true,
    reward: "Miles",
  },
  467295: {
    type: "SMTK",
    org: "VISA",
    canInstallment: true,
    reward: "Miles",
    isCommercial: true,
  },
  474123: {
    type: "BNSTK",
    org: "VISA",
    canInstallment: true,
    isCommercial: true,
  },
  474151: {
    type: "SM",
    org: "VISA",
    canInstallment: false,
    reward: "Miles",
  },
  474213: {
    type: "BNSSANAL",
    org: "VISA",
    canInstallment: true,
    isCommercial: true,
  },
  474215: {
    type: "MGRBNS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  47421500: {
    type: "MGRBNS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  47421501: {
    type: "MGRBNS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  474528: {
    type: "SMTK",
    org: "VISA",
    canInstallment: true,
  },
  479660: {
    type: "HCEBONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  479661: {
    type: "HCEBONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  479662: {
    type: "HCEPRCRD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  479682: {
    type: "TPARACRD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  482489: {
    type: "MGRBNS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  482490: {
    type: "MGRBNS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  482491: {
    type: "MGRBNS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  487074: {
    type: "BONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  487075: {
    type: "BONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  48707547: {
    type: "BONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  489455: {
    type: "PARACARD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  48945549: {
    type: "PARACARD",
    org: "VISA",
    canInstallment: false,
    reward: "Bonus",
  },
  489478: {
    type: "BONUS",
    org: "VISA",
    canInstallment: true,
    reward: "Bonus",
  },
  490175: {
    type: "GBKK",
    org: "VISA",
    canInstallment: true,
  },
  492186: {
    type: "GBKK",
    org: "VISA",
    canInstallment: true,
  },
  492187: {
    type: "GBKK",
    org: "VISA",
    canInstallment: true,
  },
  492193: {
    type: "GBKK",
    org: "VISA",
    canInstallment: true,
  },
  493845: {
    type: "GBKK",
    org: "VISA",
    canInstallment: true,
  },
  514915: {
    type: "SM",
    org: "MASTER",
    canInstallment: true,
    reward: "Miles",
  },
  516943: {
    type: "IGRPCARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  516961: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  517040: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  51704049: {
    type: "PREPAID",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  517041: {
    type: "DEBITSNL",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  517042: {
    type: "PREPAID",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  517048: {
    type: "PREPAID",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  540036: {
    type: "GBKK",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  540037: {
    type: "BNSTK",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  540118: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  540669: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  54066975: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  540709: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  541865: {
    type: "FLEXI",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  542030: { type: "BONUS", org: "MASTER", canInstallment: true },
  543738: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  544078: {
    type: "BNSSANAL",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  544294: { type: "GBKK", org: "MASTER", canInstallment: true },
  54493707: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Miles",
  },
  54493758: {
    type: "SM",
    org: "MASTER",
    canInstallment: true,
    reward: "Miles",
  },
  54544545: { type: "SF", org: "VISA", canInstallment: false },
  545698: { type: "PREPAID", org: "VISA", canInstallment: false },
  548935: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  549997: { type: "SF", org: "MASTER", canInstallment: true },
  553130: {
    type: "BNSTK",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  554253: {
    type: "HCEBONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  554254: {
    type: "HCESM",
    org: "MASTER",
    canInstallment: true,
    reward: "Miles",
  },
  554960: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  55496031: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  55496033: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  55496063: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  55496065: {
    type: "BONUS",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
  },
  55592019: {
    type: "SM",
    org: "MASTER",
    canInstallment: true,
    reward: "Miles",
  },
  557023: { type: "SANAL", org: "MASTER", canInstallment: true },
  558699: {
    type: "BNSTK",
    org: "MASTER",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  589318: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  600208: {
    type: "PREPAID",
    org: "GARANTI",
    canInstallment: false,
    reward: "Bonus",
  },
  622403: {
    type: "DRGBNS",
    org: "CUP",
    canInstallment: false,
    reward: "Bonus",
  },
  639516: { type: "PREPAID", org: "GARANTI", canInstallment: false },
  65086700: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  65086800: {
    type: "BNSDSC",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  65086801: {
    type: "BNSDSC",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  65086802: {
    type: "BNSDSC",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  65086803: {
    type: "BNSDSFLX",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  65086804: {
    type: "BNSSANAL",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  65086805: {
    type: "BNSDSC",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  65086900: {
    type: "BNDTK",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  65086901: {
    type: "BNDCG",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  65086902: {
    type: "BNDCP",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  65086903: {
    type: "BNDTK",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  65877200: {
    type: "BNDCG",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  65877201: {
    type: "BNDCP",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
    isCommercial: true,
  },
  670606: {
    type: "PREPAID",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  676255: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  676283: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  676651: {
    type: "PARACARD",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  676827: {
    type: "PREPAID",
    org: "MASTER",
    canInstallment: false,
    reward: "Bonus",
  },
  814868: {
    type: "BNSTRY",
    org: "VISA",
    canInstallment: true,
    isCommercial: true,
  },
  979205: {
    type: "BNSFLEXI",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97922083: {
    type: "HCEBONUS",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97922900: {
    type: "BNTTK",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  97922901: {
    type: "BNTCG",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  97922902: {
    type: "BNTCP",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  97922903: {
    type: "BNTTK",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
    isCommercial: true,
  },
  97922904: {
    type: "BNSTRY",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97922905: {
    type: "BNSTRY",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97922906: {
    type: "BNSTRY",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97922907: {
    type: "BNSSANAL",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97922950: {
    type: "HCEBONUS",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  979236: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923613: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923640: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923641: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923644: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923646: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923648: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97923649: {
    type: "PARACARD",
    org: "TROY",
    canInstallment: false,
    reward: "Bonus",
  },
  97928400: {
    type: "BNSTRY",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
  97928401: {
    type: "BNSSANAL",
    org: "TROY",
    canInstallment: true,
    reward: "Bonus",
  },
};

// Özel stil tanımlamaları
const PaymentCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: "linear-gradient(135deg, #1A237E 0%, #0D47A1 100%)",
  color: "white",
  borderRadius: "20px",
  marginBottom: theme.spacing(3),
  minHeight: "220px",
  position: "relative",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
    borderRadius: "20px",
  },
}));

const CardLogo = styled("img")({
  position: "absolute",
  right: 24,
  top: 24,
  height: 40,
  width: "auto",
  objectFit: "contain",
  padding: 6,
  borderRadius: 8,
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
});

// Kart numarası stili
const CardNumberTypography = styled(Typography)({
  letterSpacing: "2px",
  fontSize: "1.5rem",
  fontWeight: 500,
  textShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
});

// Özel fiyat kartı stili
const PriceCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(4),
  background: "linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)",
  position: "relative",
  overflow: "hidden",
  border: "1px solid #e0e0e0",
  borderRadius: "16px",
  boxShadow: "0 4px 24px rgba(0, 0, 0, 0.06)",
}));

const DiscountPrice = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "white",
  padding: theme.spacing(3, 4),
  borderRadius: "12px",
  marginTop: theme.spacing(2),
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
  },
}));

// Garanti logosu için özel stil
const GarantiBBVALogo = styled("img")({
  height: 32,
  marginLeft: 8,
  verticalAlign: "middle",
});

const GarantiPaymentPage = () => {
  const auth = useAuthUser();
  const user = auth();
  const [cardData, setCardData] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCVV: "",
    cardHolderName: "",
  });
  
  // Add new state variables for terms
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Test için her zaman true
  const [isGarantiCard, setIsGarantiCard] = useState(null);
  const originalPrice = 99999;
  const discountedPrice = 69999;

  // Kart bilgilerini analiz et
  const cardInfo = useMemo(() => {
    if (cardData.cardNumber.length >= 6) {
      const cardNumber = cardData.cardNumber.replace(/\s/g, "");
      const bin6 = cardNumber.substring(0, 6);
      const bin7 = cardNumber.substring(0, 7);
      const bin8 = cardNumber.substring(0, 8);

      // Debug için detaylı log
      console.log({
        enteredBin6: bin6,
        enteredBin7: bin7,
        enteredBin8: bin8,
        binExists6: bin6 in GARANTI_BINS,
        binExists7: bin7 in GARANTI_BINS,
        binExists8: bin8 in GARANTI_BINS,
      });

      // Önce 8 haneli BIN'i kontrol et, sonra 7 haneli, en son 6 haneli
      let binInfo = null;
      let usedBin = null;

      if (bin8 in GARANTI_BINS) {
        binInfo = GARANTI_BINS[bin8];
        usedBin = bin8;
      } else if (bin7 in GARANTI_BINS) {
        binInfo = GARANTI_BINS[bin7];
        usedBin = bin7;
      } else if (bin6 in GARANTI_BINS) {
        binInfo = GARANTI_BINS[bin6];
        usedBin = bin6;
      }

      if (binInfo) {
        setIsGarantiCard(true);
        return {
          ...binInfo,
          logo: `/card_logos/${usedBin}.jpg`,
          name: binInfo.type,
        };
      } else {
        setIsGarantiCard(false);
      }
    } else {
      setIsGarantiCard(null);
    }
    return null;
  }, [cardData.cardNumber]);

  const canMakeInstallments = useMemo(() => {
    return Boolean(
      cardInfo && cardInfo.canInstallment && cardInfo.type !== "PARACARD"
    );
  }, [cardInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "cardNumber") {
      formattedValue = value
        .replace(/\s/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim()
        .slice(0, 19);
    }

    if (name === "cardExpiry") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d{0,2})/, "$1/$2")
        .slice(0, 5);
    }

    setCardData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleSubmit = async () => {
    try {
      const cleanCardNumber = cardData.cardNumber.replace(/\s/g, "");

      const paymentData = {
        cardNumber: cleanCardNumber,
        cardExpiry: cardData.cardExpiry,
        cardCVV: cardData.cardCVV,
        amount: discountedPrice,
        customerEmail: user && user.email ? user.email : "",
        cardHolderName: cardData.cardHolderName,
      };

      const response = await fetch(
        "https://payment.themegamerchant.com/api/payments/process",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(paymentData),
        }
      );

      const data = await response.json();

      if (data.success) {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = data.gatewayURL;

        Object.entries(data.formData).forEach(([key, value]) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
      }
    } catch (error) {
      console.error("Payment Error:", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      {/* Fiyat Bilgisi - Yeni Tasarım */}
      <PriceCard>
        <DiscountPrice>
          <Box sx={{ textAlign: "center" }}>
            {/* Garanti BBVA Logo ve Başlık */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                mb: 3,
              }}
            >
              <img
                src="/logos/garantibbva.png"
                alt="Garanti BBVA"
                style={{ height: 32 }}
              />
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                'ya Özel
              </Typography>
            </Box>

            {/* Normal Fiyat */}
            <Typography
              variant="h6"
              sx={{
                textDecoration: "line-through",
                opacity: 0.7,
                mb: 2,
              }}
            >
              Normal Fiyat: {originalPrice.toLocaleString("tr-TR")} TL
            </Typography>

            {/* İndirimli Fiyat */}
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                backgroundColor: "rgba(255,255,255,0.1)",
                px: 3,
                py: 1,
                borderRadius: 2,
                display: "inline-block",
              }}
            >
              {discountedPrice.toLocaleString("tr-TR")} TL
            </Typography>
          </Box>
        </DiscountPrice>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mt: 2, textAlign: "center", fontStyle: "italic" }}
        >
          * Bu özel fiyat sadece Garanti BBVA kartları için geçerlidir
        </Typography>
      </PriceCard>

      {/* Kredi Kartı Görünümü */}
      <PaymentCard elevation={4}>
        {cardInfo ? (
          <CardLogo
            src={cardInfo.logo}
            alt={cardInfo.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/logos/garantibbva.png";
            }}
            sx={{ right: 24 }}
          />
        ) : (
          <CardLogo
            src="/logos/garantibbva.png"
            alt="Garanti BBVA"
            sx={{ right: 24 }}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardNumberTypography sx={{ mb: 3 }}>
              {cardData.cardNumber || "**** **** **** ****"}
            </CardNumberTypography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ opacity: 0.8, mb: 0.5 }}>
              Kart Sahibi
            </Typography>
            <Typography sx={{ fontWeight: "500" }}>
              {cardData.cardHolderName || "AD SOYAD"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ opacity: 0.8, mb: 0.5 }}>
              Son Kullanma
            </Typography>
            <Typography sx={{ fontWeight: "500" }}>
              {cardData.cardExpiry || "MM/YY"}
            </Typography>
          </Grid>
          {cardInfo && (
            <>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 2,
                    backgroundColor: "rgba(255,255,255,0.1)",
                    display: "inline-block",
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 1,
                  }}
                >
                  {cardInfo.name}
                  {cardInfo.isCommercial && " - Ticari Kart"}
                </Typography>
              </Grid>
              {cardInfo.reward && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="secondary">
                    {cardInfo.reward} Kazanırsınız
                  </Typography>
                </Grid>
              )}
              {canMakeInstallments && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="success.main">
                    Taksit Yapılabilir
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </PaymentCard>

      {/* Garanti Kart Uyarısı */}
      {isGarantiCard === false && (
        <Alert
          severity="warning"
          sx={{ mb: 3 }}
          action={
            <Button
              color="primary"
              variant="outlined"
              href="https://www.garantibbva.com.tr/isim-icin/kartlar-pos/ticari-kredi-kartlari"
              target="_blank"
              size="small"
            >
              Ticari Kart Başvurusu
            </Button>
          }
        >
          Bu kampanya sadece Garanti BBVA kartları ile geçerlidir.
          {cardInfo &&
            cardInfo.type === "PARACARD" &&
            " Banka/Kredi kartı kullanmanız gerekmektedir."}
        </Alert>
      )}

      {/* Form */}
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Kart Numarası"
                name="cardNumber"
                value={cardData.cardNumber}
                onChange={handleInputChange}
                inputProps={{ maxLength: 19 }}
                error={isGarantiCard === false}
                helperText={
                  isGarantiCard === false
                    ? "Lütfen Garanti BBVA kartınızı kullanın"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Kart Sahibinin Adı"
                name="cardHolderName"
                value={cardData.cardHolderName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Son Kullanma Tarihi"
                name="cardExpiry"
                value={cardData.cardExpiry}
                onChange={handleInputChange}
                inputProps={{ maxLength: 5 }}
                placeholder="MM/YY"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="CVV"
                name="cardCVV"
                value={cardData.cardCVV}
                onChange={handleInputChange}
                inputProps={{ maxLength: 3 }}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                }
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Button 
                      variant="text" 
                      onClick={() => setTermsModalOpen(true)}
                      sx={{ p: 0, textTransform: 'none', minWidth: 'auto', mr: 0.5, fontSize: '0.95rem' }}
                    >
                      Ön bilgilendirme formunu
                    </Button>
                    <Typography sx={{ fontSize: '0.95rem' }}>
                      okudum ve kabul ediyorum.
                    </Typography>
                  </Box>
                }
                sx={{ mb: 2 }}
              />
              <Modal
                open={termsModalOpen}
                onClose={() => setTermsModalOpen(false)}
                aria-labelledby="terms-modal-title"
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 600,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  maxHeight: '90vh',
                  overflow: 'auto'
                }}>
                  <Typography id="terms-modal-title" variant="h6" component="h2" gutterBottom>
                    Ön Bilgilendirme Formu
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {`Merhaba,

Öncelikle E-İhracata Başlangıç Paketi'ni tercih ettiğiniz için teşekkür ederiz. Size daha iyi bir hizmet sunabilmek adına, satın almış olduğunuz hizmetle ilgili süreçlerimiz hemen başlatılmıştır.

Hizmet Sürecimiz Hakkında:
1. Paket kapsamında belirtilen hizmetleri gerçekleştirmek üzere, ihtiyaçlarınıza yönelik detaylı bir analiz ve araştırma çalışması başlatılmıştır. Bu kapsamda, en kısa sürede uzman ekibimiz sizinle iletişime geçerek hizmet detaylarını paylaşacaktır.
2. Bu hizmet, tamamen size/şirketinize özel olarak planlanmış ve uygulanacak nitelikte bir yapıdadır.

Bu nedenle, söz konusu hizmetin sunulmasına yönelik hazırlıkların başlatılmasıyla birlikte cayma hakkının kullanılması mümkün olmamaktadır. İlgili mevzuat uyarınca, hizmetin size özel olarak sağlanacak olması ve süreçlerin başlamış olması nedeniyle satın alma işlemi sonrasında iade yapılamayacağını önemle hatırlatırız.

Herhangi bir sorunuz ya da talebiniz olması durumunda bizimle iletişime geçmekten çekinmeyiniz. Size destek olmaktan mutluluk duyarız.

Saygılarımızla,
MegaMerchant`}
                  </Typography>
                </Box>
              </Modal>
              <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                disabled={!isGarantiCard || !termsAccepted}
                onClick={handleSubmit}
              >
                {isGarantiCard
                  ? "Ödemeyi Tamamla"
                  : "Sadece Garanti BBVA Kartları Geçerlidir"}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GarantiPaymentPage;
